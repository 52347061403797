import React from "react"
import { graphql } from "gatsby"
import { useTranslation } from "react-i18next"
import PageRoot from "../components/PageRoot"
import EventureGrid from "../components/EventureGrid"
import { renderCarouselItem } from "../utils"
import ContainerWithPadding from "../components/ContainerWithPadding"
import moment from "moment"
import { Empty } from "antd"

const AllSpecialPricePage = ({ data }) => {
  const { t } = useTranslation()
  const specialPrices = data.eventure.special_prices

  const now = moment()

  const dataSource = specialPrices
    .filter(item => !item.end_at || moment(item.end_at) > now)
    .map(item => {
      const { video, course, certification, gift_card, bundle } = item
      const result = {
        ...(video || course || certification || gift_card || bundle),
      }

      result.special_price = item
      return result
    })

  return (
    <PageRoot title={t("label:allSpecialPrices")} showTitle>
      <ContainerWithPadding>
        <EventureGrid
          colSettings={{ xs: 24, sm: 12, md: 6, lg: 6 }}
          dataSource={dataSource}
          emptyItem={
            <Empty description={t("label:noSpecialPricesAvailable")} />
          }
          renderItem={renderCarouselItem}
        />
      </ContainerWithPadding>
    </PageRoot>
  )
}

export const query = graphql`
  query allSpecialPrices {
    eventure {
      special_prices(order_by: { end_at: asc_nulls_last }) {
        final_price
        start_at
        end_at
        video {
          ...PreviewCardVideo
        }
        course {
          ...PreviewCardCourse
        }
        certification {
          ...PreviewCardCertification
        }
        gift_card {
          ...PreviewCardGiftCard
        }
        bundle {
          ...PreviewCardBundle
        }
      }
    }
  }
`

export default AllSpecialPricePage
